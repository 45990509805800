import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Redirect} from "react-router"
import AuthWelcome from './AuthWelcome'
import {AcceptInvite} from "../actions/authActions"

const Invite=(props)=> {
    const dispatch =useDispatch()
    const networkResponse = useSelector(state =>state?.networkResponse)
    const initialState= {}
   const [userInputs, setUserInputs] = useState(initialState)

    const handleChange = (e)=>{
        const {name, value} = e.target
        setUserInputs({...userInputs, [name]:value})
 
    }
 
    const handleSubmit =(e)=>{
        e.preventDefault()
        const {password, confirm_password} = userInputs
        let data = {password, confirm_password, hash:props.match.params.hash}
        dispatch(AcceptInvite(data))
    }

    if (networkResponse?.redirectTo) {
        return <Redirect to={networkResponse.redirectTo} />;
      }
    
 
    return (
        <>
        <div className="body--container" style={{overflow:"hidden"}}>
        <div className="auth--container">
        <div className="auth-card__container">
        <AuthWelcome />
        <form onSubmit ={handleSubmit}>
        <div className="form--container__auth">
        { localStorage.getItem("invited by") && <p style={{
           fontWeight: 900, 
           fontSize:"1.1rem",      
           color: "#303030",
         }}>You have been invited by {" "} 
            {localStorage.getItem("invited by")} {" "}
            to use BMAC </p>}
        <div className="forgot-pass">
         
          <div className="input--container">
            <p className="text">Password</p>
            <input
              type="password"
              name="password"
              className="input-login password"
              placeholder=""
              onChange={handleChange}
            />
          </div>
          <div className="input--container">
            <p className="text">Confirm password</p>
            <input
              type="password"
              name="confirm_password"
              onChange={handleChange}
              className="input-login password"
              placeholder="enter your password"
            />
  
          </div> 
            <button className="btn-green" >Send </button>
        </div>
      </div>
      </form>   
        </div>
      </div>
      </div>
      
      </>
    )
}

export default Invite;