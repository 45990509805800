import React from 'react'
import ResetPassword from "../components/ResetPassword"
import AuthWelcome from "../components/AuthWelcome"

const ResetPasswordComp = () => {

    return (
      <>
       <div className="body--container" style={{overflow:"hidden"}}>
        <div className="auth--container">
        <div className="auth-card__container">
        <AuthWelcome />
        <ResetPassword />
        </div>
         </div>
         </div> 
        </>     
      
    );
}

export default ResetPasswordComp;