const process = {
    env: {
        NODE_ENV: "development",
        SERVICE_URL: "https://invoice.backend.onebmac.com/api/",
       //  SERVICE_URL: "http://127.0.0.1:8000/api/",
        IMG_URL: "https://invoice.backend.onebmac.com/img/",
       //  IMG_URL: "http://localhost:8000/img/"
            // IMG_URL: "http://127.0.0.1:8000/business/images/",
    },
};

export default process;
