import {SEND_INVITE_EMPLOYEE, SEND_EMPLOYEE_INVITE_LOADING_STARTS, SEND_EMPLOYEE_INVITE_LOADING_ENDS, GET_INVITE_EMPLOYEE, GET_EMPLOYEE_INVITE_LOADING_ENDS, GET_EMPLOYEE_INVITE_LOADING_STARTS, DELETE_USER, DELETE_USER_LOADING_ENDS, DELETE_USER_LOADING_STARTS,
EDIT_PERMISSION_ENDS, EDIT_PERMISSION_STARTS, GET_PERMISSION_STARTS, GET_PERMISSION_ENDS, GET_PERMISSION, SEND_ADMIN_INVITE_LOADING_STARTS
} from "../actions/types";

const initialState = {
    loading: false,
    response:null,
    data:[],
    operationInProgress:false,
    currentUserRole:{}
};

export default function invoice (state = initialState, action) {
    switch (action.type) {
        case SEND_EMPLOYEE_INVITE_LOADING_STARTS:
            return {
                ...state,
                loading:true
            }
        case SEND_ADMIN_INVITE_LOADING_STARTS:
            return {
                ...state,
                loading:true
            }
        case SEND_EMPLOYEE_INVITE_LOADING_ENDS:
            return {
                ...state,
               loading:false
            }
        case SEND_INVITE_EMPLOYEE:
            return {
                ...state,
                response:action.payload
               
            }

        case GET_INVITE_EMPLOYEE:
            return{
                ...state,
                data:action.payload
            }
        case DELETE_USER:
            return{
                ...state,
                data: state.data.filter(user => user.id !== action.payload)
            }
        case DELETE_USER_LOADING_STARTS:
            return{
                ...state,
                operationInProgress:true
            }
        case DELETE_USER_LOADING_ENDS:
            return{
                ...state,
                operationInProgress:false
            }
        case EDIT_PERMISSION_STARTS:
            return{
                ...state,
                operationInProgress:true
            }
        case EDIT_PERMISSION_ENDS:
            return{
                ...state,
                operationInProgress:false
            }

        case GET_PERMISSION_ENDS:
            return{
                ...state,
                operationInProgress:false
            }

            case GET_PERMISSION_STARTS:
                return{
                    ...state,
                    operationInProgress:true
                }
        case GET_PERMISSION:
            return{
                ...state,
                currentUserRole:action.payload
            }
        case GET_EMPLOYEE_INVITE_LOADING_STARTS:
            return{
                ...state,
                loading:true
            }
        case GET_EMPLOYEE_INVITE_LOADING_ENDS:
            return{
                ...state,
                loading:false
            }
        default:
            return state;
    }
}