import {
    DELETE_QUOTE,
    GET_ALL_QUOTE,
    QUOTE_LOADING_STARTS,
    QUOTE_LOADING_ENDS,
    GET_SINGLE_QUOTE,
    QUOTE_EDITING_STARTS,
    QUOTE_EDITING_ENDS,
    CRUD_QUOTE_STARTS,
    CRUD_QUOTE_ENDS,

  } from "../actions/types";
  
  const initialState = {
    quote: [],
    singleQuote:{},
    loading: false,
    opsInProgress:false
  };
  
  export default function quote(state = initialState, action) {
    switch (action.type) {
      case GET_ALL_QUOTE:
        return {
          ...state,
          quote: action.payload.data,
          totalQty:action.payload.num
        };
      case QUOTE_LOADING_STARTS:
        return {
          ...state,
          loading: true,
        };
      case QUOTE_LOADING_ENDS:
        return {
          ...state,
          loading: false,
        };
      case QUOTE_EDITING_ENDS:
        return {
          ...state,
          opsInProgress: false,
        };
      case QUOTE_EDITING_STARTS:
        return {
          ...state,
          opsInProgress: true,
        };
      case CRUD_QUOTE_STARTS:
        return {
          ...state,
          opsInProgress: true,
        };
      case CRUD_QUOTE_ENDS:
        return {
          ...state,
          opsInProgress: false,
        };
      case GET_SINGLE_QUOTE:
       // console.log(action.payload, "payload");
        return {
          ...state,
          singleQuote: action.payload.data,
          totalQty:action.payload.num
        };
      case DELETE_QUOTE:
        return {
          ...state,
          quote: state.quote?.data.filter((item) => item.id !== action.payload),
        };
      default:
        return state;
    }
  }
  