import { PAYMENT_LOADING_ENDS, PAYMENT_LOADING_STARTS, PAYMENT_RECORDED} from "../actions/types";

const initialState = {
    loading: false,
    data:{}
};

export default function invoice (state = initialState, action) {
    switch (action.type) {
        case PAYMENT_LOADING_ENDS:
            return {
                ...state,
                loading:false
            }
        case PAYMENT_LOADING_STARTS:
            return {
                ...state,
                loading:true
            }
        case PAYMENT_RECORDED:
            return {
                ...state,
                data:action.payload
            }
        default:
            return state;
    }
}