import { LOG_OUT_USER, SET_CURRENT_USER, CHANGE_PASSWORD_ENDS, CHANGE_PASSWORD_STARTS, FORGOT_PASSWORD_SUCCESS } from "../../actions/types";
import isEmpty from "../../utils/isEmpty";

const initialState = {
  isAuthenticated: false,
  user: {},
  loading:false,
  isSuccess:""
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case LOG_OUT_USER:
      return {
        ...state,
        user: {},
      };
    case CHANGE_PASSWORD_STARTS:
      return {
        ...state,
        loading:true
      };
    case CHANGE_PASSWORD_ENDS:
      return {
        ...state,
        loading:false,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isSuccess:action.payload
      };
    default:
      return state;
  }
}
