import React, {useState, useEffect} from 'react'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {validateUser} from "../API/index"
import { LoadingInnerDashboard } from './SkeletonLoading/ResourceSkeleton';
import Invite from "./Invite"
import { notify } from "../utils/notification"


export default function AcceptInviteUser(props) {
    let hash = props.match.params.hash
    const initialState= {
        loading:null,
        error:null,
        isValid:null
    }

    useEffect(() => {
      setUserInputs({...userInputs, loading:true, error:false})
      const fetch = async()=>{
        try{
          await validateUser(hash)
          setUserInputs({...userInputs, loading:false, error:false, isValid:true})

        }

        catch(e){
        //  console.log("errors", e)
          setUserInputs({...userInputs, loading:false, error:true, isValid:false})
          return notify("invite not valid", "error")

        }
      }

      fetch()

    }, [hash])
   const [userInputs, setUserInputs] = useState(initialState)
    

   const {loading, error, isValid} = userInputs
   const _statusLoading =loading &&!error && <LoadingInnerDashboard text="Pleas wait..." />
   const _statusError =!loading && error &&<p>Invalid token, pls request another invite</p>
   const _userAcceptsInv = isValid && !error && <Invite {...props} />
 
    return (

       <>
      {_statusError}
      {_statusLoading}
      {_userAcceptsInv}
      <ToastContainer />
       </> 
    
    )
}
