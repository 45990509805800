import { GET_ALL_CURRENCIES, GET_ALL_SECTORS } from "../actions/types";


const initialState = {
    sectors: [],
    currencies: []
};

export default function Error (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_SECTORS:
            return {
                ...state,
                sectors: action.payload
            }
        case GET_ALL_CURRENCIES:
            return {
                ...state,
                currencies: action.payload
            };
        default:
            return state;
    }

}