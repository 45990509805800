import {SHOW_CONFIRMATION_MODAL, HIDE_CONFIRMATION_MODAL, SHOW_SUCCESS_MODAL, HIDE_SUCCESS_MODAL, SHOW_RECURRING_RECEIPT_MODAL, HIDE_RECURRING_RECEIPT_MODAL}  from "../actions/types"



const initialState = {
showConfirmationModal:false,
showSuccessModal:false,
currentId:null,
showRecurringReceiptModal:false,
}


export default function modal  (state = initialState, action) {
    switch (action.type) {
        case SHOW_CONFIRMATION_MODAL:
           
            return {
                ...state,
                currentId:action.payload,
                showConfirmationModal:true
            }
        case HIDE_CONFIRMATION_MODAL:
            return {
                ...state,
                showConfirmationModal:false
            }
        case SHOW_SUCCESS_MODAL:
            return {
                ...state,
                showSuccessModal:true
            }
        case HIDE_SUCCESS_MODAL:
            return {
                ...state,
                showSuccessModal:false
            }
        case SHOW_RECURRING_RECEIPT_MODAL:
            return {
                ...state,
                showRecurringReceiptModal:true
            }
        case HIDE_RECURRING_RECEIPT_MODAL:
            return {
                ...state,
                showRecurringReceiptModal:false
            }

            default:
                return state
        }
    }
