import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {withRouter, Redirect} from "react-router-dom"
import { resetPassword }from "../actions/authActions"
import {
    mustContainsOneNumericCharacter,
    mustContainsOneUppercaseAlphabet,
    mustContainsOneLowercaseAlphabet,
  } from "../utils/inputValidation";
  import {notify} from "../utils/notification"

function Resetnew_password(props) {
    const dispatch = useDispatch()
    const {isLoading:{loading}, networkResponse} = useSelector(state =>state)
    let initData ={}
    const [inputDetails, setInputDetails] = useState(initData)
    const [pwdError, setPwdError] = useState("");
    const handleChange =(e)=>{
        const {name, value} = e.target
        setInputDetails({...inputDetails, [name]:value})
        
    }

    const handleSubmit =(e)=>{
        e.preventDefault()
        const {new_password, confirm_newPassword} = inputDetails
        const token=  props.match.params.hash
        let data ={new_password, confirm_newPassword, token}


        if (inputDetails.new_password !== inputDetails.confirm_newPassword) {
            return notify("new_password must match!", "error");
          }
          if (inputDetails.new_password.length <8 || inputDetails.confirm_newPassword.length <8) {
            setPwdError("The new_password must be at least 8 characters.");
            setTimeout(() => setPwdError(""), 3000);
            return notify("The new_password must be at least 8 characters.", "error");
          }
          if (!mustContainsOneLowercaseAlphabet.test(inputDetails.new_password)) {
            setPwdError("must contains at least one lowercase character");
            setTimeout(() => setPwdError(""), 3000);
            return notify("must contains at least one lowercase character.", "error");
          } else if (!mustContainsOneUppercaseAlphabet.test(inputDetails.new_password)) {
            setPwdError("must contains at least one uppercase character");
            setTimeout(() => setPwdError(""), 3000);
            return notify("must contains at least one lowercase character.", "error");
          } else if (!mustContainsOneNumericCharacter.test(inputDetails.new_password)) {
            setPwdError("must contains at least one numeric character");
            setTimeout(() => setPwdError(""), 3000);
            return notify("must contains at least one numeric character", "error");
          }

        dispatch(resetPassword(data))       
    }


    if (networkResponse?.redirectTo) {
        return <Redirect to={networkResponse.redirectTo} />;
      }
    return (
        <form onSubmit={handleSubmit}>
        <div className="side-lines-right">
          <span className="side-line-right light-green"></span>
        </div>
        <div className="form--container__auth">
          <div className="forgot-pass">
            <div className="input--container">
              <p className="text">New password</p>
              <input
              name="new_password"
               type="password"
                className="input-login new_password"
                placeholder=""
                onChange={handleChange}
                required
              />
            </div>
            <div className="input--container">
              <p className="text">Confirm password</p>
              <input
              name="confirm_newPassword"
                type="password"
                className="input-login newpassword"
                placeholder="enter your new password"
                onChange={handleChange}
                required
              />
              <div className="auth-error-text">
              </div>
            </div>

           
              <button className="btn-green">{loading ? "Requesting": "Change password"}</button>
           
          </div>
        </div>
      </form>
    )
}

export default (withRouter)(Resetnew_password)
