export const LOG_OUT_USER = "LOG_OUT_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const IS_ADMIN = "IS_ADMIN";
export const NOT_ADMIN = "NOT_ADMIN";
export const SET_LOADER = "SET_LOADER";
export const STOP_LOADER = "STOP_LOADER";

export const IS_LOADING = "IS_LOADING";
export const NOT_LOADING = "NOT_LOADING";
export const REDIRECT_TO = "REDIRECT_TO";
export const SHOW_NETWORK_RESPONSE = "SHOW_NETWORK_RESPONSE";
export const SHOW_NETWORK_RESPONSE_MOD = "SHOW_NETWORK_RESPONSE_MOD";
export const CLEAR_NETWORK_RESPONSE = "CLEAR_NETWORK_RESPONSE";
export const FETCH_ERROR = "FETCH_ERROR";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_FETCH_ERRORS = "CLEAR_FETCH_ERRORS";
export const START_OPERATION_IN_PROGRESS = "START_OPERATION_IN_PROGRESS";
export const END_OPERATION_IN_PROGRESS = "END_OPERATION_IN_PROGRESS";

export const INVOICE = "INVOICE";
export const ITEM = "ITEM";
export const CUSTOMER = "CUSTOMER";

//Operation
export const DELETE = "DELETE";
export const UPDATE = "UPDATE";

//status
export const STARTS = "STARTS";
export const ENDS = "ENDS";

export const operationStatus = (targetItem, operationType, status) => {
  return `${targetItem}_${operationType}_${status}`;
};

export const CLEAR_NOT_VERIFIED = "CLEAR_NOT_VERIFIED";
export const IS_VERIFIED = "IS_VERIFIED";
export const NOT_VERIFIED = "NOT_VERIFIED";

export const GET_BUSINESS_PROFILE = "GET_BUSINESS_PROFILE";
export const GET_USER_INFO = "GET_USER_INFO";
export const PROFILE_LOADING_STARTS = "PROFILE_LOADING_STARTS";
export const PROFILE_LOADING_ENDS = "PROFILE_LOADING_ENDS";

export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const GET_SINGLE_CUSTOMER = "GET_SINGLE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const CUSTOMER_LOADING_STARTS = "CUSTOMER_LOADING_STARTS";
export const CUSTOMER_LOADING_ENDS = "CUSTOMER_LOADING_ENDS";
export const CUSTOMER_CRUD_STARTS = "CUSTOMER_LOADING_STARTS";
export const CUSTOMER_CRUD_ENDS = "CUSTOMER_LOADING_ENDS";

export const GET_ALL_ITEMS = "GET_ALL_ITEMS";
export const ITEMS_LOADING_STARTS = "ITEMS_LOADING_STARTS";
export const ITEMS_LOADING_ENDS = "ITEMS_LOADING_ENDS";
export const ITEM_NETWORK_RESPONSE = "ITEM_NETWORK_RESPONSE";
export const GET_ITEM = "GET_ITEM";
export const DELETE_ITEM = "DELETE_ITEM ";
export const FILTER_ITEM = "FILTER_ITEM ";
export const GET_INVOICES_BY_ITEM_ID = "GET_INVOICES_BY_ITEM_ID";

export const GET_ALL_BANKS = "GET_ALL_BANKS";
export const BANK_LOADING_STARTS = "BANK_LOADING_STARTS";
export const BANK_LOADING_ENDS = "BANK_LOADING_ENDS";
export const BANK_NETWORK_RESPONSE = "BANK_NETWORK_RESPONSE";
export const GET_BANK = "GET_BANK";
export const DELETE_BANK = "DELETE_BANK";
export const UPDATE_BANK = "UPDATE_BANK";
export const CREATE_BANK = "CREATE_BANK";

export const GET_ALL_INVOICE = "GET_ALL_INVOICE";
export const GET_SINGLE_INVOICE = "GET_SINGLE_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE ";
export const INVOICE_LOADING_STARTS = "INVOICE_LOADING_STARTS";
export const INVOICE_LOADING_ENDS = "INVOICE_LOADING_ENDS";
export const INVOICE_CRUD_STARTS = "INVOICE_CRUD_STARTS";
export const INVOICE_CRUD_ENDS = "INVOICE_CRUD_ENDS";

export const GET_TERMS = "GET_TERMS";

export const PAYMENT_LOADING_STARTS = "PAYMENT_LOADING_STARTS";
export const PAYMENT_LOADING_ENDS = "PAYMENT_LOADING_ENDS";
export const PAYMENT_RECORDED = "PAYMENT_RECORDED";

//Modals
export const SHOW_ITEM_MODAL = "SHOW_ITEM_MODAL";
export const HIDE_ITEM_MODAL = "HIDE_ITEM_MODAL";

export const SHOW_CUSTOMER_MODAL = "SHOW_CUSTOMER_MODAL";
export const HIDE_CUSTOMER_MODAL = "HIDE_CUSTOMER_MODAL";

export const SHOW_BANK_MODAL = "SHOW_BANK_MODAL";
export const HIDE_BANK_MODAL = "HIDE_BANK_MODAL";

export const SHOW_RECORD_PAYMENT_MODAL = "SHOW_RECORD_PAYMENT_MODAL";
export const HIDE_RECORD_PAYMENT_MODAL = "HIDE_RECORD_PAYMENT_MODAL";

export const SHOW_CONFIRMATION_MODAL = "SHOW_CONFIRMATION_MODAL";
export const HIDE_CONFIRMATION_MODAL = "HIDE_CONFIRMATION_MODAL";

export const SHOW_PAYMENT_SUCESS_MODAL = "SHOW_PAYMENT_SUCESS_MODAL";
export const HIDE_PAYMENT_SUCESS_MODAL = "HIDE_PAYMENT_SUCESS_MODAL";
export const SHOW_SEND_RECEIPT_MODAL = "SHOW_SEND_RECEIPT_MODAL";
export const HIDE_SEND_RECEIPT_MODAL = "HIDE_SEND_RECEIPT_MODAL";
export const SHOW_SEND_RECEIPT_SUCESS_MODAL = "SHOW_SEND_RECEIPT_SUCESS_MODAL";
export const SHOW_RECURRING_RECEIPT_MODAL = "SHOW_RECURRING_RECEIPT_MODAL";
export const HIDE_RECURRING_RECEIPT_MODAL = "HIDE_RECURRING_RECEIPT_MODAL";

export const HIDE_SUCCESS_MODAL = "HIDE_SUCESS_MODAL";
export const SHOW_SUCCESS_MODAL = "SHOW_SUCESS_MODAL";
export const SHOW_TAX_MODAL = "SHOW_TAX_MODAL";
export const HIDE_TAX_MODAL = "HIDE_TAX_MODAL";

export const SHOW_SEND_INVOICE_DRAFT_MODAL = "SHOW_SEND_INVOICE_DRAFT_MODAL";
export const HIDE_SEND_INVOICE_DRAFT_MODAL = "HIDE_SEND_INVOICE_DRAFT_MODAL";

//Change Password

export const CHANGE_PASSWORD_STARTS = "CHANGE_PASSWORD_STARTS";
export const CHANGE_PASSWORD_ENDS = "CHANGE_PASSWORD_ENDS";

//Forgot Password
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

//Others
export const UPLOAD_PERCENTAGE = "UPLOADE_PERCENTAGE";
export const TOTAL_FILE_SIZE = "TOTAL_FILE_SIZE";
export const CURRENT_FILE_SIZE_UPLOADED = "CURRENT_FILE_SIZE_UPLOADED";
export const UPLOADED_OBJECT = "UPLOADED_OBJECT";
export const UPLOADED_OBJECT_COMPLETE = "UPLOADED_OBJECT_COMPLETE";
export const UPLOAD_STARTS = "UPLOAD_STARTS";
export const UPLOAD_ENDS = "UPLOAD_ENDS";

//Dashboard

export const DASHBOARD_LOADING_STARTS = "DASHBOARD_LOADING_STARTS";
export const DASHBOARD_LOADING_ENDS = "DASHBOARD_LOADING_ENDS";
export const GET_INVOICE_STATS = "GET_INVOICE_STATS";
export const GET_INVOICE_TOP_ITEMS = "GET_INVOICE_TOP_ITEMS";
export const GET_INVOICE_TOP_CUSTOMERS = "GET_INVOICE_TOP_ITEMS";
export const GET_ACCOUNT_STATUS = "GET_ACCOUNT_STATUS";

//send invite
export const SEND_EMPLOYEE_INVITE_LOADING_STARTS =
  "SEND_EMPLOYEE_INVITE_LOADING_STARTS";
export const SEND_EMPLOYEE_INVITE_LOADING_ENDS =
  "SEND_EMPLOYEE_INVITE_LOADING_ENDS";
export const SEND_INVITE_EMPLOYEE = "SEND_INVITE_EMPLOYEE";

export const SEND_ADMIN_INVITE_LOADING_STARTS =
  "SEND_ADMIN_INVITE_LOADING_STARTS";
export const SEND_ADMIN_INVITE_LOADING_ENDS = "SEND_ADMIN_INVITE_LOADING_ENDS";
export const SEND_INVITE_ADMIN = "SEND_INVITE_ADMIN";

export const GET_EMPLOYEE_INVITE_LOADING_STARTS =
  "GET_EMPLOYEE_INVITE_LOADING_STARTS";

export const GET_EMPLOYEE_INVITE_LOADING_ENDS =
  "GET_EMPLOYEE_INVITE_LOADING_ENS";
export const GET_INVITE_EMPLOYEE = "GET_INVITE_EMPLOYEE";
export const DELETE_USER_LOADING_STARTS = "DELETE_USER_LOADING_STARTS";
export const DELETE_USER_LOADING_ENDS = "DELETE_USER_LOADING_ENDS";
export const DELETE_USER = "DELETE_USER";

//Permissions
export const EDIT_PERMISSION_ENDS = "EDIT_PERMISSION_ENDS";
export const EDIT_PERMISSION_STARTS = "EDIT_PERMISSION_STARTS";
export const GET_PERMISSION_STARTS = "GET_PERMISSION_STARTS";
export const GET_PERMISSION_ENDS = "GET_PERMISSION_ENDS";
export const GET_PERMISSION = "GET_PERMISSION";

//Tax
export const CRUD_TAX_STARTS = "CRUD_TAX_STARTS";
export const CRUD_TAX_ENDS = "CRUD_TAX_ENDS";
export const GET_ALL_TAXES = "GET_ALL_TAXES";

//Transaction Report
export const GET_ALL_INVOICE_REPORT = "GET_ALL_INVOICE_REPORT";
export const TRANSACTION_REPORT_LOADING_STARTS =
  "TRANSACTION_REPORT_LOADING_STARTS";
export const TRANSACTION_REPORT_LOADING_ENDS =
  "TRANSACTION_REPORT_LOADING_ENDS";
export const TRANSACTION_REPORT_CRUD_STARTS = "TRANSACTION_REPORT_CRUD_STARTS";
export const TRANSACTION_REPORT_CRUD_ENDS = "TRANSACTION_REPORT_CRUD_ENDS";
export const FILTER_INVOICE_REPORT = "FILTER_INVOICE_REPORT";
export const DELETE_SINGLE_REPORT = "DELETE_SINGLE_REPORT";

export const GET_ALL_CURRENCIES = "GET_ALL_CURRENCIES";
export const GET_ALL_SECTORS = "GET_ALL_SECTORS";

export const GET_ALL_QUOTE = "GET_ALL_QUOTE";
export const GET_SINGLE_QUOTE = "GET_SINGLE_QUOTE";
export const DELETE_QUOTE = "DELETE_QUOTE ";
export const QUOTE_LOADING_STARTS = "QUOTE_LOADING_STARTS";
export const QUOTE_LOADING_ENDS = "QUOTE_LOADING_ENDS";
export const QUOTE_EDITING_STARTS = "QUOTE_EDITING_STARTS";
export const QUOTE_EDITING_ENDS = "QUOTE_EDITING_ENDS";
export const CRUD_QUOTE_ENDS = "CRUD_QUOTE_ENDS";
export const CRUD_QUOTE_STARTS = "CRUD_QUOTE_STARTS";
