import Skeleton from 'react-loading-skeleton';
export const LoadingTableHeader=()=> <Skeleton height={50} width={"100%"}/>
export const LoadingTableRows=()=> {
  const style = {
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center"
  }
    return (
        <>
        <div className="text-center tableRowDiv" style={style}>
        {new Array(5).fill().map((i, idx)=> 
        <div className="tableRowChild" key={idx}> 
            <Skeleton className = "rowChild"width={120} height={30}/>
        </div>
        ) 
        }         
        </div>
        </>
    )
}


export const LoadingBizProfile =()=>{
    return (
        <>
           <div className="business--container__title">
               {new Array(2).fill().map((i, index)=>(  
                  <Skeleton key={index} className = "rowChild"width={140} height={30}/>
               ))}  
          </div>
                  <div className="text-center mb-2 mt-1">
                  <Skeleton circle={true} height={100} width={100} />
                                           
              </div>

              {new Array(2).fill().map((i, index)=> 
            <div className="border-grey mb-1" key={index}> 
              <Skeleton className = "mb-1"width={80} height={14}/>
              <br />
              <Skeleton className = "mb-1"width={80} height={14}/>
            </div>
          ) 
          }
          
        
        </>
    )
}

export const LoadingInnerDashboard=({text})=>{
    const styles =
{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    height:"70vh",
    flexDirection:"column"
    
  } 

    return(
        <>
        <div style={styles}>
        <div className="loadingmenu">
            <div></div>
            <div></div>
            <div></div>
        </div>        
          <p>{text} </p>
          </div>
          
        </>

    )
}

//load dashboard when opening the app for the fist time
const _Cards = 
<div className="invoice__cards animate__animated animate__fadeIn">
<div className="flex--container">
    <div className="invoices">
        <p className="text-lg">
         <Skeleton width={120} height={25} className="text-lg"/>
        </p>
        
        <span className="green-text h1-bold">
        <Skeleton width={120} height={35} className="text-lg"/>
            </span>
    </div>
    <Skeleton width={120} height={40} className="text-lg"/>
</div>
</div>

export const LoadingDashboard=()=>{
  
    return(
        <>
         <div className="dashboard--container">
         <nav className="navbar--container">
      <div className="header-nav__item">
        <div className="logo-dashboard">
        <Skeleton height={24} width={40} />
        </div>
        <div className="hamburger">
        </div>
      </div>
      <div className="navbar-item__container">
      <Skeleton height={30} width={120}/>
        <div className="left--items">
        <Skeleton circle={true} height={24} width={24} />
        <Skeleton circle={true} height={24} width={24} />
          <div className="">
        <Skeleton circle={true} height={45} width={45} />
          </div>
        </div>
      </div>
    </nav>

    <div className="side-nav"> 
     <div className="inner-nav">
     {new Array(5).fill().map((item, index)=> 
    <div key={index} className="side-nav__item">
    <Skeleton width={40} height={20}/>
    <Skeleton width={90} height={20}  className="nav-text"/>
    </div>)}
     </div>
   
   </div>         
   <div className="dashboard--flex__container">
    <div className="inner-dashboard__container">
      {/* <div className="inner-page--container">
      <div className="title-bar">
        {new Array(2).fill().map((item, index)=>  <Skeleton width={120} height={ 30}/>)}
        </div>
        <div className="card--container mt-2">
            <div className="grid--container__dashboard">
            {new Array(2).fill().map((item, index)=> _Cards)}
            </div>
        </div>
         </div> */}
         </div>
         </div>
         </div>
        </>

    )
}

export const LoadingUserDashboard =()=>{

  return(
    <>
    <div className="inner-page--container">
      <div className="title-bar">
        {new Array(2).fill().map((item, index)=>  <Skeleton key={index} width={120} height={ 30}/>)}
        </div>
        <div className="card--container mt-2">
            <div className="grid--container__dashboard">
            {new Array(2).fill().map((item, index)=> _Cards)}
            </div>
        </div>
         </div>
      
    </>
  )

}