import { DELETE_CUSTOMER, GET_ALL_CUSTOMERS, CUSTOMER_LOADING_STARTS, CUSTOMER_LOADING_ENDS, CUSTOMER_CRUD_STARTS, CUSTOMER_CRUD_ENDS } from "../actions/types";


const initialState = {
    customers: [],
    loading:false,
    totalQty:"",
    opsInProgress:false,
};

export default function auth (state = initialState, action) {
    switch (action.type) {
        case "CUSTOMER_CRUD_STARTS":
            return {
                ...state,
                opsInProgress:true,
            }
        case "CUSTOMER_CRUD_ENDS":
            return {
                ...state,
                opsInProgress:false,
            }
        case GET_ALL_CUSTOMERS:
            return {
                ...state,
                customers: action.payload.data,
                totalQty:action.payload?.num
            }
        case CUSTOMER_LOADING_STARTS:
            return {
                ...state,
                loading: true
            }
        case CUSTOMER_LOADING_ENDS:
            return {
                ...state,
                loading: false
            }
        case DELETE_CUSTOMER:
            return {
                ...state,
                customers: state.customers?.data.filter(item => item.id !== action.payload)
            }
            case "fu":
                return {
                    ...state,
                    customers: state.customers.filter(item => item.id !== action.payload)
                }
            case CUSTOMER_LOADING_ENDS:
                return {
                    ...state,
                    opsInProgress:false
                }
        default:
            return state;
    }
}